<template>
    <b-navbar toggleable="lg" variant="primary" type="dark">
        <b-navbar-brand href="#"><img src="/assets/logo-ec-diap-290.png" width="290" height="auto" class="mr-4"> {{ fullName }}</b-navbar-brand>
        <b-navbar-toggle target="getoggeld"></b-navbar-toggle>
        <b-collapse id="getoggeld" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/">Home</b-nav-item>
              <b-nav-item to="/objectlist">Onderwerpen</b-nav-item>
              <b-nav-item v-if="showGroups" to="/grouplist">Groepen</b-nav-item>
            </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/" @click="logOut"><b-icon-box-arrow-right></b-icon-box-arrow-right></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import {getUserInfo, logoutUser} from "@/utils/auth";
// import router from "@/router";

export default {
  name: "Nav",
  data() {
    return {
      fullName: '',
        showGroups: false
    }
  },
  methods: {
    logOut: function() {
      logoutUser();
      // router.push('/');
      window.location.reload();
    }
  },
  created() {
    let userData = getUserInfo();
    if(userData.fullname) {
      this.fullName = userData.fullname;
    }
    if(userData.groups === 1) {
        this.showGroups = true;
    }
    console.log('userData: ' + JSON.stringify(userData));
  }
}
</script>

<style scoped>

</style>
