<template>
    <b-container>
        <b-row>
          <h3>Onderwerp: {{ objectIdentifier }}: {{ objectTitle }} - Tag: {{ objectTag }}, {{ groupTag }}</h3>
          <b-icon-eye class="ml-auto h2" @click="preview"></b-icon-eye>
        </b-row>
        <b-row>
            <b-col cols="7">
                <b-form class="w-100 mx-auto">
                  <b-form-group
                      v-if="showGroups"
                    id="select-groep"
                    label="Groep / Locatie"
                    label-for="group">
                    <b-select
                        id="group"
                        v-model="groupTag"
                        :options="groupOptions"
                        @change="modNL"
                    >
                    </b-select>

                  </b-form-group>
                    <b-tabs v-model="tabIndex">
                        <b-tab title="Nederlands" active>
                            <b-form-group
                                    id="input-titel-nl"
                                    label="Titel (Nederlands)"
                                    label-for="input-titel-nl">
                                <b-form-input
                                        id="titel-nl"
                                        v-model="form.titel_nl"
                                        type="text"
                                        placeholder="Nederlandse titel voor onderwerp"
                                        @input="modNL"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-nl"
                                    label="Omschrijving (Nederlands)"
                                    label-for="input-omschrijving-nl">
<!--                                <editor
                                    id="omschrijving-nl"
                                    @input="modNL"
                                    api-key="0xuz59mwgiyu1l18uif64ov4a8e23bwtiog4gbxo87agv1xm"
                                    :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: ['lists paste help wordcount'],
                                    toolbar:
                                    'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | help'
                                    }"
                                    v-model="form.omschrijving_nl"
                                ></editor> -->
                              <ckeditor
                                  id="omschrijving-nl"
                              :editor="editor"
                              v-model="form.omschrijving_nl"
                              :config="editorConfigNL"
                              @input="modNL"></ckeditor>
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Duits">
                            <b-form-group
                                    id="input-titel-de"
                                    label="Titel (Duits)"
                                    label-for="input-titel-de">
                                <b-form-input
                                        id="titel-de"
                                        v-model="form.titel_de"
                                        type="text"
                                        placeholder="Duitse titel voor onderwerp"
                                        @input="modDE"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-de"
                                    label="Omschrijving (Duits)"
                                    label-for="input-omschrijving-de">
                              <ckeditor
                                  id="omschrijving-de"
                                  :editor="editor"
                                  v-model="form.omschrijving_de"
                                  :config="editorConfigDE"
                                  @input="modDE"></ckeditor>
<!--                                <editor
                                    id="omschrijving-de"
                                    @input="modDE"
                                    api-key="0xuz59mwgiyu1l18uif64ov4a8e23bwtiog4gbxo87agv1xm"
                                    :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: ['lists paste help wordcount'],
                                    toolbar:
                                    'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | help'
                                    }"
                                    v-model="form.omschrijving_de"
                                ></editor>-->
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Engels">
                            <b-form-group
                                    id="input-titel-en"
                                    label="Titel (Engels)"
                                    label-for="input-titel-en">
                                <b-form-input
                                        id="titel-en"
                                        v-model="form.titel_en"
                                        type="text"
                                        placeholder="Engelse titel voor onderwerp"
                                        @input="modEN"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-en"
                                    label="Omschrijving (Engelse)"
                                    label-for="input-omschrijving-en">
                              <ckeditor
                                  id="omschrijving-en"
                                  :editor="editor"
                                  v-model="form.omschrijving_en"
                                  :config="editorConfigEN"
                                  @input="modEN"></ckeditor>
<!--                                <editor
                                    id="omschrijving-en"
                                    @input="modEN"
                                    api-key="0xuz59mwgiyu1l18uif64ov4a8e23bwtiog4gbxo87agv1xm"
                                    :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: ['lists paste help wordcount'],
                                    toolbar:
                                    'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | help'
                                    }"
                                    v-model="form.omschrijving_en"
                                ></editor>-->
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Frans">
                            <b-form-group
                                    id="input-titel-fr"
                                    label="Titel (frans)"
                                    label-for="input-titel-fr">
                                <b-form-input
                                        id="titel-fr"
                                        v-model="form.titel_fr"
                                        type="text"
                                        placeholder="Franse titel voor onderwerp"
                                        @input="modFR"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-fr"
                                    label="Omschrijving (Frans)"
                                    label-for="input-omschrijving-fr">
                              <ckeditor
                                  id="omschrijving-fr"
                                  :editor="editor"
                                  v-model="form.omschrijving_fr"
                                  :config="editorConfigFR"
                                  @input="modFR"></ckeditor>
<!--                                <editor
                                    id="omschrijving-fr"
                                    @input="modFR"
                                    api-key="0xuz59mwgiyu1l18uif64ov4a8e23bwtiog4gbxo87agv1xm"
                                    :init="{
                                    height: 400,
                                    menubar: false,
                                    plugins: ['lists paste help wordcount'],
                                    toolbar:
                                    'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | help'
                                    }"
                                    v-model="form.omschrijving_fr"
                                ></editor>-->
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Extra taal">
                            <b-form-group
                                id="input-titel-x"
                                label="Titel (extra)"
                                label-for="input-titel-x">
                                <b-form-input
                                    id="titel-x"
                                    v-model="form.titel_x"
                                    type="text"
                                    placeholder="Titel voor onderwerp (extra taal)"
                                    @input="modX"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="input-omschrijving-x"
                                label="Omschrijving (extra)"
                                label-for="input-omschrijving-x">
                              <ckeditor
                                  id="omschrijving-x"
                                  :editor="editor"
                                  v-model="form.omschrijving_x"
                                  :config="editorConfigX"
                                  @input="modX"></ckeditor>
<!--                                <editor
                                    id="omschrijving-x"
                                    @input="modX"
                                    api-key="0xuz59mwgiyu1l18uif64ov4a8e23bwtiog4gbxo87agv1xm"
                                    :init="{
                                    height: 400,
                                    menubar: false,
                                    plugins: ['lists paste help wordcount'],
                                    toolbar:
                                    'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | help'
                                    }"
                                    v-model="form.omschrijving_x"
                                ></editor>-->
                            </b-form-group>
                        </b-tab>
                      <b-tab title="Gebarentaal">
                        <b-form-group
                            id="input-titel-gt"
                            label="Titel (gebarentaal)"
                            label-for="input-titel-gt">
                          <b-form-input
                              id="titel-gt"
                              v-model="form.titel_gt"
                              type="text"
                              placeholder="Titel voor onderwerp (gebarentaal)"
                              @input="modGT"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-omschrijving-gt"
                            label="Omschrijving (gebarentaal)"
                            label-for="input-omschrijving-gt">
                          <ckeditor
                              id="omschrijving-gt"
                              :editor="editor"
                              v-model="form.omschrijving_gt"
                              :config="editorConfigGT"
                              @input="modGT"></ckeditor>
<!--                          <editor
                              id="omschrijving-gt"
                              @input="modGT"
                              api-key="0xuz59mwgiyu1l18uif64ov4a8e23bwtiog4gbxo87agv1xm"
                              :init="{
                                    height: 400,
                                    menubar: false,
                                    plugins: ['lists paste help wordcount'],
                                    toolbar:
                                    'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | help'
                                    }"
                              v-model="form.omschrijving_gt"
                          ></editor>-->
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                    <b-button type="submit" :disabled="!modifiedNL && !modifiedDE && !modifiedEN && !modifiedFR && !modifiedX && !modifiedGT" variant="primary" @submit.prevent @click="saveText">Bewaar tekstwijzigingen</b-button>
                </b-form>
            </b-col>
            <b-col>
                <b-img v-if="fileImage.url" :src="fileImage.url" center fluid class="h-auto w-75"></b-img>
                <b-form-file style="overflow: hidden"
                    class="mt-3"
                    :state="Boolean(fileImageUpload)"
                    v-model="fileImageUpload"
                    placeholder="Selecteer of sleep een afbeelding..."
                    drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
                <b-button :disabled="!Boolean(fileImageUpload)" variant="primary" class="w-100 mt-3" @click="submitImageFile">Bewaar of wijzig afbeelding</b-button>
                <b-button :disabled="!fileImage.url" variant="danger" class="w-100 mt-3" @click="deleteImageFile">Verwijder afbeelding</b-button>
            </b-col>
        </b-row>
      <b-row class="mt-3 bg-secondary text-white px-3">
        <h3>Audio</h3>
      </b-row>
        <b-row cols-lg="5" cols-sm="2">
            <b-col>
                <h4>
                    <b-icon-play-fill v-if="fileAudioNL.url" @click="playNL" :variant="audioNLPlaying ? 'danger' : 'primary'"></b-icon-play-fill>
                    <b-icon-play v-if="!fileAudioNL.url"></b-icon-play>
                    Nederlands
                </h4>
                <audio
                        v-if="fileAudioNL && fileAudioNL.url"
                        id="playerNL"
                        @ended="endNL"
                >
                    <source :src="fileAudioNL.url" :type="fileAudioNL.type">
                </audio>
                <b-form-file style="overflow: hidden"
                    class="mt-3"
                    :state="Boolean(fileAudioNLUpload)"
                    v-model="fileAudioNLUpload"
                    placeholder="Selecteer of sleep..."
                    drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
              <b-button :disabled="!Boolean(fileAudioNLUpload)" variant="primary" class="w-100 mt-3" @click="submitAudioNLFile">Bewaar of wijzig</b-button>
              <b-button :disabled="!fileAudioNL.url" variant="danger" class="w-100 mt-3" @click="deleteAudioNLFile">Verwijder</b-button>
            </b-col>
            <b-col>
                <h4>
                    <b-icon-play-fill v-if="fileAudioDE.url" @click="playDE" :variant="audioDEPlaying ? 'danger' : 'primary'"></b-icon-play-fill>
                    <b-icon-play v-if="!fileAudioDE.url"></b-icon-play>
                    Duits
                </h4>
                <audio
                        v-if="fileAudioDE && fileAudioDE.url"
                        id="playerDE"
                        @ended="endDE"
                >
                    <source :src="fileAudioDE.url" :type="fileAudioDE.type">
                </audio>
                <b-form-file style="overflow: hidden"
                    class="mt-3"
                    :state="Boolean(fileAudioDEUpload)"
                    v-model="fileAudioDEUpload"
                    placeholder="Selecteer of sleep..."
                    drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
                <b-button :disabled="!Boolean(fileAudioDEUpload)" variant="primary" class="w-100 mt-3" @click="submitAudioDEFile">Bewaar of wijzig</b-button>
                <b-button :disabled="!fileAudioDE.url" variant="danger" class="w-100 mt-3" @click="deleteAudioDEFile">Verwijder</b-button>
            </b-col>
            <b-col>
                <h4>
                    <b-icon-play-fill v-if="fileAudioEN.url" @click="playEN" :variant="audioENPlaying ? 'danger' : 'primary'"></b-icon-play-fill>
                    <b-icon-play v-if="!fileAudioEN.url"></b-icon-play>
                    Engels
                </h4>
                <audio
                        v-if="fileAudioEN && fileAudioEN.url"
                        id="playerEN"
                        @ended="endEN"
                >
                    <source :src="fileAudioEN.url" :type="fileAudioEN.type">
                </audio>
                <b-form-file style="overflow: hidden"
                    class="mt-3"
                    :state="Boolean(fileAudioENUpload)"
                    v-model="fileAudioENUpload"
                    placeholder="Selecteer of sleep..."
                    drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
                <b-button :disabled="!Boolean(fileAudioENUpload)" variant="primary" class="w-100 mt-3" @click="submitAudioENFile">Bewaar of wijzig</b-button>
                <b-button :disabled="!fileAudioEN.url" variant="danger" class="w-100 mt-3" @click="deleteAudioENFile">Verwijder</b-button>
            </b-col>
            <b-col>
                <h4>
                    <b-icon-play-fill v-if="fileAudioFR.url" @click="playFR" :variant="audioFRPlaying ? 'danger' : 'primary'"></b-icon-play-fill>
                    <b-icon-play v-if="!fileAudioFR.url"></b-icon-play>
                    Frans
                </h4>
                <audio
                        v-if="fileAudioFR && fileAudioFR.url"
                        id="playerFR"
                        @ended="endFR"
                >
                    <source :src="fileAudioFR.url" :type="fileAudioFR.type">
                </audio>
                <b-form-file style="overflow: hidden"
                    class="mt-3"
                    :state="Boolean(fileAudioFRUpload)"
                    v-model="fileAudioFRUpload"
                    placeholder="Selecteer of sleep..."
                    drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
                <b-button :disabled="!Boolean(fileAudioFRUpload)" variant="primary" class="w-100 mt-3" @click="submitAudioFRFile">Bewaar of wijzig</b-button>
                <b-button :disabled="!fileAudioFR.url" variant="danger" class="w-100 mt-3" @click="deleteAudioFRFile">Verwijder</b-button>
            </b-col>
            <b-col>
                <h4>
                    <b-icon-play-fill v-if="fileAudioX.url" @click="playX" :variant="audioXPlaying ? 'danger' : 'primary'"></b-icon-play-fill>
                    <b-icon-play v-if="!fileAudioX.url"></b-icon-play>
                    Extra taal
                </h4>
                <audio
                    v-if="fileAudioX && fileAudioX.url"
                    id="playerX"
                    @ended="endX"
                >
                    <source :src="fileAudioX.url" :type="fileAudioX.type">
                </audio>
                <b-form-file style="overflow: hidden"
                             class="mt-3"
                             :state="Boolean(fileAudioXUpload)"
                             v-model="fileAudioXUpload"
                             placeholder="Selecteer of sleep..."
                             drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
                <b-button :disabled="!Boolean(fileAudioXUpload)" variant="primary" class="w-100 mt-3" @click="submitAudioXFile">Bewaar of wijzig</b-button>
                <b-button :disabled="!fileAudioX.url" variant="danger" class="w-100 mt-3" @click="deleteAudioXFile">Verwijder</b-button>
            </b-col>
        </b-row>
      <b-row class="mt-3 bg-secondary text-white px-3">
        <h3>Video</h3>
      </b-row>
      <b-row cols-lg="2" cols-sm="1">
        <b-col>
          <h4>
            <b-icon-play-fill v-if="fileVideoGT.url" @click="playVideoGT" :variant="videoGTPlaying ? 'danger' : 'primary'"></b-icon-play-fill>
            <b-icon-play v-if="!fileVideoGT.url"></b-icon-play>
            Gebarentaal
          </h4>
          <b-modal
              id="modal-video"
              hide-footer
              size="lg"
          >
            <b-container class="video-container align-content-center">
              <video
                  v-if="fileVideoGT && fileVideoGT.url"
                  width="100%"
                  height="500px"
                  id="playerGT"
                  @ended="endGT"
                  controls
              >
                <source :src="fileVideoGT.url" :type="fileVideoGT.type">
              </video>

            </b-container>

          </b-modal>
<!--          <video-->
<!--              v-if="fileVideoGT && fileVideoGT.url"-->
<!--              id="playerGT"-->
<!--              @ended="endGT"-->
<!--          >-->
<!--            <source :src="fileVideoGT.url" :type="fileVideoGT.type">-->
<!--          </video>-->
          <b-form-file style="overflow: hidden"
                       class="mt-3"
                       :state="Boolean(fileVideoGTUpload)"
                       v-model="fileVideoGTUpload"
                       placeholder="Selecteer of sleep..."
                       drop-placeholder="Laat 'm hier maar vallen..."
          ></b-form-file>
          <b-button :disabled="!Boolean(fileVideoGTUpload)" variant="primary" class="w-100 mt-3" @click="submitVideoGTFile">Bewaar of wijzig</b-button>
          <b-button :disabled="!fileVideoGT.url" variant="danger" class="w-100 mt-3" @click="deleteVideoGTFile">Verwijder</b-button>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
    // import Editor from '@tinymce/tinymce-vue';
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import axios from 'axios';
    import config from '../../public/config/api.json';
    import {getUserInfo} from "@/utils/auth";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    let configApi = null;
    if(config.is_production) {
        configApi = config.production;
    } else {
        configApi = config.test;
    }

    export default {
        name: "Object",
        components: {
          ckeditor: CKEditor.component
        },
        props: ['id'],
        data() {
            return {
              editor: ClassicEditor,
              editorConfigNL: {
                language: 'nl',
                toolbar: {
                  items: ['heading', 'bold', 'italic', '|', 'alignment', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                },
                height: '300px'
              },
              editorConfigDE: {
                language: 'de',
                toolbar: {
                  items: ['heading', 'bold', 'italic', '|', 'alignment', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                },
                height: '300px'
              },
              editorConfigEN: {
                language: 'en',
                toolbar: {
                  items: ['heading', 'bold', 'italic', '|', 'alignment', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                },
                height: '300px'
              },
              editorConfigFR: {
                language: 'fr',
                toolbar: {
                  items: ['heading', 'bold', 'italic', '|', 'alignment', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                },
                height: '300px'
              },
              editorConfigX: {
                toolbar: {
                  items: ['heading', 'bold', 'italic', '|', 'alignment', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                },
                height: '300px'
              },
              editorConfigGT: {
                language: 'nl',
                toolbar: {
                  items: ['heading', 'bold', 'italic', '|', 'alignment', '|', 'undo', 'redo', '|', 'numberedList', 'bulletedList']
                },
                height: '300px'
              },
              userData: {},
                objectId: null,
                objectIdentifier: '',
                objectTitle: '',
                objectOwner: null,
                objectTag: '',
                groupTag: '',
                groupOptions:[],
                showGroups: false,
                tabIndex: 0,
                form: {
                    titel_nl: '',
                    omschrijving_nl: '',
                    titel_de: '',
                    omschrijving_de: '',
                    titel_en: '',
                    omschrijving_en: '',
                    titel_fr: '',
                    omschrijving_fr: '',
                    titel_x: '',
                    omschrijving_x: '',
                    titel_gt: '',
                    omschrijving_gt: ''
                },
                modifiedNL: false,
                modifiedDE: false,
                modifiedEN: false,
                modifiedFR: false,
                modifiedX: false,
                modifiedGT: false,
                fileAudioNL: {},
                fileAudioNLUpload: null,
                fileAudioDE: {},
                fileAudioDEUpload: null,
                fileAudioEN: {},
                fileAudioENUpload: null,
                fileAudioFR: {},
                fileAudioFRUpload: null,
                fileAudioX: {},
                fileAudioXUpload: null,
                fileVideoGT: {},
                fileVideoGTUpload: null,
                fileImage: {},
                fileImageUpload: null,
                audioNLPlaying: false,
                audioDEPlaying: false,
                audioENPlaying: false,
                audioFRPlaying: false,
                audioXPlaying: false,
                videoGTPlaying: false
            }
        },
        methods: {
          preview: function() {
            // alert('tag: '+ this.objectTag);
            let languageLabel = '';
            if(this.tabIndex === 0) {
              languageLabel = 'nl';
            }
            if(this.tabIndex === 1) {
              languageLabel = 'de';
            }
            if(this.tabIndex === 2) {
              languageLabel = 'en';
            }
            if(this.tabIndex === 3) {
              languageLabel = 'fr';
            }
              if(this.tabIndex === 4) {
                  languageLabel = 'x';
              }
            if(this.tabIndex === 5) {
              languageLabel = 'gt';
            }
            if(this.objectTag === 'instruct' || this.objectTag === 'welcome' || this.objectTag === 'intro') {
              let previewURL = 'https://' + this.userData.name + '.easycast-services.nl/#/' + this.objectTag + '/' + languageLabel;
              window.open(previewURL, '_blank','width=480, height=800, toolbar=no, menubar=no, location=no');
            } else {
              let previewURL = 'https://' + this.userData.name + '.easycast-services.nl/#/object/' + languageLabel + '/' + this.objectId;
              window.open(previewURL, '_blank','width=480, height=800, toolbar=no, menubar=no, location=no');
            }
          },
            // video - start
          submitVideoGTFile: function () {
            let formVideoGTData = new FormData();
            let fileVideoGT = this.fileVideoGTUpload;
            formVideoGTData.append('file', fileVideoGT);
            const recordVideoGTData = `
                {
                    "order": 1,
                    "item": {"id": ${this.objectId}},
                    "element_texts": [
                        {
                            "html": false,
                            "text": "video-gt",
                            "element": {"id": 50}
                        }
                    ]
                }
                `;
            // console.log('recordAudioNLData' + recordAudioNLData);
            formVideoGTData.append('data', recordVideoGTData);
            if(this.fileVideoGT.id) {
              // video GT found
              axios.delete(configApi.api_url + 'files/' + this.fileVideoGT.id + '?key=' + this.userData.apikey)
                  .then(() => {
                    axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                        formVideoGTData,
                        {
                          headers: {
                            'Content-Type': 'multipart/form-data'
                          }
                        }
                    ).then(function(){
                      console.log('SUCCESS!!');
                      window.location.reload();
                    })
                        .catch(function(){
                          console.log('FAILURE!!');
                        });

                  })
                  .catch(console.log('verwijderen video: ' + this.fileVideoGT.id + ' mislukt!'));
            } else {
              // no video GT found
              axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                  formVideoGTData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
              ).then(function(){
                console.log('SUCCESS!!');
                window.location.reload();
              })
                  .catch(function(){
                    console.log('FAILURE!!');
                  });
            }
          },
          deleteVideoGTFile: function() {
            if(this.fileVideoGT.id) {
              // video GT found
              axios.delete(configApi.api_url + 'files/' + this.fileVideoGT.id + '?key=' + this.userData.apikey)
                  .then(() => {
                    window.location.reload();
                  })
                  .catch(console.log('verwijderen video: ' + this.fileVideoGT.id + ' mislukt!'));
            } else {
              console.log('verwijderen video (GT) mislukt: file niet gevonden!')
              window.location.reload();
            }
          },
            // video - end
            submitAudioXFile: function () {
                let formAudioXData = new FormData();
                let fileAudioX = this.fileAudioXUpload;
                formAudioXData.append('file', fileAudioX);
                const recordAudioXData = `
                {
                    "order": 1,
                    "item": {"id": ${this.objectId}},
                    "element_texts": [
                        {
                            "html": false,
                            "text": "audio-x",
                            "element": {"id": 50}
                        }
                    ]
                }
                `;
                // console.log('recordAudioNLData' + recordAudioNLData);
                formAudioXData.append('data', recordAudioXData);
                if(this.fileAudioX.id) {
                    // audio fr found
                    axios.delete(configApi.api_url + 'files/' + this.fileAudioX.id + '?key=' + this.userData.apikey)
                        .then(() => {
                            axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                                formAudioXData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            ).then(function(){
                                console.log('SUCCESS!!');
                                window.location.reload();
                            })
                                .catch(function(){
                                    console.log('FAILURE!!');
                                });

                        })
                        .catch(console.log('verwijderen audio: ' + this.fileAudioX.id + ' mislukt!'));
                } else {
                    // no audio x found
                    axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                        formAudioXData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function(){
                        console.log('SUCCESS!!');
                        window.location.reload();
                    })
                        .catch(function(){
                            console.log('FAILURE!!');
                        });
                }
            },
            deleteAudioXFile: function() {
                if(this.fileAudioX.id) {
                    // audio x found
                    axios.delete(configApi.api_url + 'files/' + this.fileAudioX.id + '?key=' + this.userData.apikey)
                        .then(() => {
                            window.location.reload();
                        })
                        .catch(console.log('verwijderen audio: ' + this.fileAudioX.id + ' mislukt!'));
                } else {
                    console.log('verwijderen audio (X) mislukt: file niet gevonden!')
                    window.location.reload();
                }
            },
            submitAudioFRFile: function () {
              let formAudioFRData = new FormData();
              let fileAudioFR = this.fileAudioFRUpload;
              formAudioFRData.append('file', fileAudioFR);
              const recordAudioFRData = `
                {
                    "order": 1,
                    "item": {"id": ${this.objectId}},
                    "element_texts": [
                        {
                            "html": false,
                            "text": "audio-fr",
                            "element": {"id": 50}
                        }
                    ]
                }
                `;
              // console.log('recordAudioNLData' + recordAudioNLData);
              formAudioFRData.append('data', recordAudioFRData);
              if(this.fileAudioFR.id) {
                // audio fr found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioFR.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                          formAudioFRData,
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data'
                            }
                          }
                      ).then(function(){
                        console.log('SUCCESS!!');
                        window.location.reload();
                      })
                          .catch(function(){
                            console.log('FAILURE!!');
                          });

                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioFR.id + ' mislukt!'));
              } else {
                // no audio fr found
                axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                    formAudioFRData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                ).then(function(){
                  console.log('SUCCESS!!');
                  window.location.reload();
                })
                    .catch(function(){
                      console.log('FAILURE!!');
                    });
              }
            },
            deleteAudioFRFile: function() {
              if(this.fileAudioFR.id) {
                // audio fr found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioFR.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioFR.id + ' mislukt!'));
              } else {
                console.log('verwijderen audio (FR) mislukt: file niet gevonden!')
                window.location.reload();
              }
            },
            submitAudioENFile: function () {
              let formAudioENData = new FormData();
              let fileAudioEN = this.fileAudioENUpload;
              formAudioENData.append('file', fileAudioEN);
              const recordAudioENData = `
                {
                    "order": 1,
                    "item": {"id": ${this.objectId}},
                    "element_texts": [
                        {
                            "html": false,
                            "text": "audio-en",
                            "element": {"id": 50}
                        }
                    ]
                }
                `;
              // console.log('recordAudioENData' + recordAudioENData);
              formAudioENData.append('data', recordAudioENData);
              if(this.fileAudioEN.id) {
                // audio EN found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioEN.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                          formAudioENData,
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data'
                            }
                          }
                      ).then(function(){
                        console.log('SUCCESS!!');
                        window.location.reload();
                      })
                          .catch(function(){
                            console.log('FAILURE!!');
                          });

                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioEN.id + ' mislukt!'));
              } else {
                // no audio nl found
                axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                    formAudioENData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                ).then(function(){
                  console.log('SUCCESS!!');
                  window.location.reload();
                })
                    .catch(function(){
                      console.log('FAILURE!!');
                    });
              }

            },
            deleteAudioENFile: function() {
              if(this.fileAudioEN.id) {
                // audio fr found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioEN.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioEN.id + ' mislukt!'));
              } else {
                console.log('verwijderen audio (EN) mislukt: file niet gevonden!')
                window.location.reload();
              }
            },
            submitAudioDEFile: function () {
              let formAudioDEData = new FormData();
              let fileAudioDE = this.fileAudioDEUpload;
              formAudioDEData.append('file', fileAudioDE);
              const recordAudioDEData = `
                {
                    "order": 1,
                    "item": {"id": ${this.objectId}},
                    "element_texts": [
                        {
                            "html": false,
                            "text": "audio-de",
                            "element": {"id": 50}
                        }
                    ]
                }
                `;
              // console.log('recordAudioDEData' + recordAudioDEData);
              formAudioDEData.append('data', recordAudioDEData);
              if(this.fileAudioDE.id) {
                // audio de found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioDE.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                          formAudioDEData,
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data'
                            }
                          }
                      ).then(function(){
                        console.log('SUCCESS!!');
                        window.location.reload();
                      })
                          .catch(function(){
                            console.log('FAILURE!!');
                          });

                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioDE.id + ' mislukt!'));
              } else {
                // no audio de found
                axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                    formAudioDEData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                ).then(function(){
                  console.log('SUCCESS!!');
                  window.location.reload();
                })
                    .catch(function(){
                      console.log('FAILURE!!');
                    });
              }
            },
            deleteAudioDEFile: function() {
              if(this.fileAudioDE.id) {
                // audio fr found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioDE.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioDE.id + ' mislukt!'));
              } else {
                console.log('verwijderen audio (DE) mislukt: file niet gevonden!')
                window.location.reload();
              }
            },
            submitAudioNLFile: function () {
                let formAudioNLData = new FormData();
                let fileAudioNL = this.fileAudioNLUpload;
                formAudioNLData.append('file', fileAudioNL);
                const recordAudioNLData = `
                {
                    "order": 1,
                    "item": {"id": ${this.objectId}},
                    "element_texts": [
                        {
                            "html": false,
                            "text": "audio-nl",
                            "element": {"id": 50}
                        }
                    ]
                }
                `;
                // console.log('recordAudioNLData' + recordAudioNLData);
                formAudioNLData.append('data', recordAudioNLData);
                if(this.fileAudioNL.id) {
                  // audio nl found
                  axios.delete(configApi.api_url + 'files/' + this.fileAudioNL.id + '?key=' + this.userData.apikey)
                      .then(() => {
                        axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                            formAudioNLData,
                            {
                              headers: {
                                'Content-Type': 'multipart/form-data'
                              }
                            }
                        ).then(function(){
                          console.log('SUCCESS!!');
                          window.location.reload();
                        })
                            .catch(function(){
                              console.log('FAILURE!!');
                            });

                      })
                      .catch(console.log('verwijderen audio: ' + this.fileAudioNL.id + ' mislukt!'));
                } else {
                  // no audio nl found
                  axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                      formAudioNLData,
                      {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                      }
                  ).then(function(){
                    console.log('SUCCESS!!');
                    window.location.reload();
                  })
                      .catch(function(){
                        console.log('FAILURE!!');
                      });
                }

              },
            deleteAudioNLFile: function() {
              if(this.fileAudioNL.id) {
                // audio nl found
                axios.delete(configApi.api_url + 'files/' + this.fileAudioNL.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(console.log('verwijderen audio: ' + this.fileAudioNL.id + ' mislukt!'));
              } else {
                console.log('verwijderen audio (NL) mislukt: file niet gevonden!')
                window.location.reload();
              }
            },
            submitImageFile: function () {
              // func
              let formImageData = new FormData();
              let fileImage = this.fileImageUpload;
              formImageData.append('file', fileImage);
              const recordImageData = `
              {
                  "order": 1,
                  "item": {"id": ${this.objectId}},
                  "element_texts": [
                      {
                          "html": false,
                          "text": "Afbeelding",
                          "element": {"id": 50}
                      }
                  ]
              }
              `;
              console.log('recordImageData' + recordImageData);
              formImageData.append('data', recordImageData);
              if(this.fileImage.id) {
                // image found
                axios.delete(configApi.api_url + 'files/' + this.fileImage.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                          formImageData,
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data'
                            }
                          }
                      ).then(function(){
                        console.log('SUCCESS!!');
                        window.location.reload();
                      })
                          .catch(function(){
                            console.log('FAILURE!!');
                          });

                    })
                    .catch(console.log('verwijderen afbeelding: ' + this.fileImage.id + ' mislukt!'));
              } else {
                // no image found
                axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                    formImageData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                ).then(function(){
                  console.log('SUCCESS!!');
                  window.location.reload();
                })
                    .catch(function(){
                      console.log('FAILURE!!');
                    });
              }
              // func
            },
            deleteImageFile: function() {
              if(this.fileImage.id) {
                // image found
                axios.delete(configApi.api_url + 'files/' + this.fileImage.id + '?key=' + this.userData.apikey)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(console.log('verwijderen afbeelding: ' + this.fileImage.id + ' mislukt!'));
              } else {
                console.log('verwijderen afbeelding mislukt: geen afbeelding gevonden!');
                window.location.reload();
              }
            },
            saveText: function() {
              let formData = new FormData();

              // console.log('NL saved: ' + this.objectTitleNL);
              console.log('saveText collection: ' + this.userData.collection);
              let tagsArray = [];
              if(this.groupTag !== '') {
                let gtToPush = {"name": `${this.groupTag}`};
                tagsArray.push(gtToPush);
              }
              if(this.objectTag !== '') {
                let otToPush = {"name": `${this.objectTag}`};
                tagsArray.push(otToPush);
              }
              let formDataText = {
                "item_type" : {"id" : 18},
                "collection" : {"id" : this.userData.collection},
                "tags": tagsArray,
                "element_texts" : [
                  {
                    "html": false,
                    "text": this.form.titel_nl,
                    "element": {"id" : 50}
                  },
                  {
                    "html": false,
                    "text": this.objectIdentifier,
                    "element": {"id" : 43}
                  },
                  {
                    "html": false,
                    "text": this.form.titel_nl,
                    "element": {"id" : 52}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving_nl,
                    "element": {"id" : 53}
                  },
                  {
                    "html": false,
                    "text": this.form.titel_de,
                    "element": {"id" : 54}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving_de,
                    "element": {"id" : 55}
                  },
                  {
                    "html": false,
                    "text": this.form.titel_en,
                    "element": {"id" : 56}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving_en,
                    "element": {"id" : 57}
                  },
                    {
                        "html": false,
                        "text": this.form.titel_fr,
                        "element": {"id" : 58}
                    },
                    {
                        "html": false,
                        "text": this.form.omschrijving_fr,
                        "element": {"id" : 59}
                    },
                    {
                        "html": false,
                        "text": this.form.titel_x,
                        "element": {"id" : 62}
                    },
                    {
                        "html": false,
                        "text": this.form.omschrijving_x,
                        "element": {"id" : 63}
                    },
                    {
                      "html": false,
                      "text": this.form.titel_gt,
                      "element": {"id" : 64}
                    },
                    {
                      "html": false,
                      "text": this.form.omschrijving_gt,
                      "element": {"id" : 65}
                    }
                ]
              };
              formData.append('data', formDataText);
              axios.put(configApi.api_url + 'items/' + this.id + '?key=' + this.userData.apikey, formDataText)
                  .then(() => {
                        this.modifiedNL = false;
                        this.modifiedDE = false;
                        this.modifiedEN = false;
                        this.modifiedFR = false;
                        this.modifiedX = false;
                        this.modifiedGT = false;
                      }
                  )
                  .catch(err => {
                    let putUrl = configApi.api_url + 'items/' + this.id + '?key=' + this.userData.apikey;
                    console.log('fout: ' + err);
                    console.log('put URL: ' + putUrl);
                    console.log('put body: ' + JSON.stringify(formDataText));
                  });
            },
            modNL: function () {
              this.modifiedNL = true;
            },
            modDE: function () {
              this.modifiedDE = true;
            },
            modEN: function () {
              this.modifiedEN = true;
            },
            modFR: function () {
                this.modifiedFR = true;
            },
            modX: function () {
              this.modifiedX = true;
            },
            modGT: function () {
              this.modifiedGT = true;
            },
            playNL: function() {
                // console.log('play NL');
                let playerNL = document.getElementById('playerNL');
                if(playerNL.paused){
                    playerNL.play();
                    this.audioNLPlaying = true;
                } else {
                    playerNL.pause();
                    this.audioNLPlaying = false;
                }
            },
            endNL: function() {
                this.audioNLPlaying = false;
            },
            playDE: function() {
                console.log('play DE');
                let playerDE = document.getElementById('playerDE');
                if(playerDE.paused){
                    playerDE.play();
                    this.audioDEPlaying = true;
                } else {
                    playerDE.pause();
                    this.audioDEPlaying = false;
                }
            },
            endDE: function() {
                this.audioDEPlaying = false;
            },
            playEN: function() {
                console.log('play EN');
                let playerEN = document.getElementById('playerEN');
                if(playerEN.paused){
                    playerEN.play();
                    this.audioENPlaying = true;
                } else {
                    playerEN.pause();
                    this.audioENPlaying = false;
                }
            },
            endEN: function() {
                this.audioENPlaying = false;
            },
            playFR: function() {
                console.log('play FR');
                let playerFR = document.getElementById('playerFR');
                if(playerFR.paused){
                    playerFR.play();
                    this.audioFRPlaying = true;
                } else {
                    playerFR.pause();
                    this.audioFRPlaying = false;
                }
            },
            endFR: function() {
                this.audioFRPlaying = false;
            },
            playX: function() {
                console.log('play X');
                let playerX = document.getElementById('playerX');
                if(playerX.paused){
                    playerX.play();
                    this.audioXPlaying = true;
                } else {
                    playerX.pause();
                    this.audioXPlaying = false;
                }
            },
            endX: function() {
                this.audioXPlaying = false;
            },
          playVideoGT: function() {
            this.$bvModal.show('modal-video');
            console.log('play Video SL');
            let playerGT = document.getElementById('playerGT');
            if(playerGT.paused){
              playerGT.play();
              this.videoGTPlaying = true;
            } else {
              playerGT.pause();
              this.videoGTPlaying = false;
            }
          },
          endGT: function() {
            this.videoGTPlaying = false;
          }
        },
        created() {
          this.userData = getUserInfo();
          if(this.userData.groups === 1) {
              this.showGroups = true;
          }
            axios.get(configApi.api_url + 'items/' + this.id + '?key=' + this.userData.apikey)
            .then((res) => {
                let objectData = res.data;
                this.objectId = objectData.id;
                this.objectOwner = objectData.owner.id;
                // this.objectTag = objectData.tags[0].name;
                if(objectData.tags && objectData.tags.length > 0) {
                  objectData.tags.forEach((tagFound) => {
                    // console.log('tagFound: ' + tagFound.name);
                    if(tagFound.name.split('-')[0] === 'audio' || tagFound.name === 'instruct' || tagFound.name === 'welcome' || tagFound.name === 'intro') {
                      this.objectTag = tagFound.name;
                      // console.log('audio-tag: ' + tagFound.name);
                    }
                    if(tagFound.name.split('-')[0] === `${this.userData.collection}`) {
                      this.groupTag = tagFound.name;
                      // console.log('group-tag: ' + tagFound.name);
                    }
                  });
                }
                // console.log('owner object: ' + this.objectOwner);
                // console.log('omeka user: ' + this.userData.apiuser);
                // console.log('omeka collection: ' + this.userData.collection);
                if(this.objectOwner !== this.userData.apiuser) {
                  alert('U mag deze gegevens niet bewerken!');
                  return window.location.href = ('/');
                }
                // console.log(JSON.stringify(res));
                // Text fields from Omeka
                objectData.element_texts.forEach((elementText) => {
                    if (elementText.element.name === 'Title'){
                        this.objectTitle = elementText.text;
                    }
                    if (elementText.element.name === 'Identifier'){
                        this.objectIdentifier = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_nl'){
                        this.form.titel_nl = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_nl'){
                        this.form.omschrijving_nl = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_de'){
                        this.form.titel_de = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_de'){
                        this.form.omschrijving_de = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_en'){
                        this.form.titel_en = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_en'){
                        this.form.omschrijving_en = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_fr'){
                        this.form.titel_fr = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_fr'){
                        this.form.omschrijving_fr = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_x'){
                        this.form.titel_x = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_x'){
                        this.form.omschrijving_x = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_gt'){
                      this.form.titel_gt = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_gt'){
                      this.form.omschrijving_gt = elementText.text;
                    }
                });
                // Files from Omeka
                axios.get(configApi.api_url + 'files/?item=' + this.id + '&key=' + this.userData.apikey)
                .then((resFiles) => {
                    let filesFound = resFiles.data;
                    // console.log(JSON.stringify(filesFound));
                    filesFound.forEach(fileFound => {
                        let fileToShow = {
                            "id": fileFound.id,
                            "label": "",
                            "url": fileFound.file_urls.original,
                            "type": fileFound.mime_type,
                            "filename": fileFound.original_filename
                        };
                        fileFound.element_texts.forEach(elementFound => {
                            if(elementFound.element.name === 'Title') {
                                fileToShow.label = elementFound.text;
                            }
                        });
                        if(fileToShow.label === 'audio-nl'){
                            this.fileAudioNL = fileToShow;
                            this.audioNLPlaying = false;
                        }
                        if(fileToShow.label === 'audio-de'){
                            this.fileAudioDE = fileToShow;
                        }
                        if(fileToShow.label === 'audio-en'){
                            this.fileAudioEN = fileToShow;
                        }
                        if(fileToShow.label === 'audio-fr'){
                            this.fileAudioFR = fileToShow;
                        }
                        if(fileToShow.label === 'audio-x'){
                          this.fileAudioX = fileToShow;
                        }
                        if(fileToShow.label === 'video-gt'){
                          this.fileVideoGT = fileToShow;
                        }
                        if(fileToShow.label === 'Afbeelding' && fileToShow.type.slice(0,6) === 'image/'){
                            this.fileImage = fileToShow;
                        }
                    })
                })
                .catch();
              //  for select options groups
              axios.get(configApi.api_url + 'items' + '?collection='+ this.userData.collection + '&item_type=19&key=' + this.userData.apikey)
                  .then((res) => {
                    const groupsFound = res.data;
                    groupsFound.forEach((groupFound, index) => {
                      let groupToPush = {
                        "value": '',
                        "text": '',
                      };
                      // first option as label
                      if(index === 0) {
                        let emptyGroupToPush = {
                          "value": '',
                          "text": "Selecteer een groep",
                        };
                        this.groupOptions.push(emptyGroupToPush);
                      }
                      // text fields
                      groupFound.element_texts.forEach((elementText) => {
                        if (elementText.element.name === 'Tag naam'){
                          groupToPush.value = elementText.text;
                        }
                        if (elementText.element.name === 'Title'){
                          groupToPush.text = elementText.text;
                        }
                      });
                      this.groupOptions.push(groupToPush);
                    });
                    // console.log(JSON.stringify(this.groupOptions))
                  })
                  .catch(err => console.log(err));
            })
            .catch();
        }
    }
</script>

<style scoped>
.video-container {
  width: 600px;
  max-height: 600px;
}
</style>
