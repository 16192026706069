import Vue from 'vue';
import VueRouter from 'vue-router';
import { isLoggedIn } from "@/utils/auth";
import Home from "@/views/Home";
import Login from "@/views/Login";
import ObjectList from "@/views/ObjectList";
import Object from "@/views/Object";
import GroupList from "@/views/GroupList";
import Group from "@/views/Group";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/objectlist',
        name: 'Objectlist',
        component: ObjectList
    },
    {
        path: '/object/:id',
        name: 'Object',
        component: Object,
        props: true
    },
    {
        path: '/grouplist',
        name: 'Grouplist',
        component: GroupList
    },
    {
        path: '/group/:id',
        name: 'Group',
        component: Group,
        props: true
    }
];

const router = new VueRouter({
    mode: 'hash',
    routes
});
router.beforeEach((to, from, next) => {
    if(!to.meta.allowAnonymous && !isLoggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
});
export default router;
