<template>
    <b-container>
      <b-row>
        <b-button class="btn-danger my-2" v-b-modal.addgroup-form>+ Voeg groep toe</b-button>
      </b-row>
        <b-row>
            <b-table
                    id="grouplist"
                    fixed
                    striped
                    hover
                    :items="groups"
                    :tbody-tr-class="rowClass"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :fields="fields"
                    @row-clicked="onGroupSelect"
            >
            </b-table>
            <b-pagination
            v-model="currentPage"
            :total-rows="groupsLength"
            :per-page="perPage"
            aria-controls="grouplist"
            ></b-pagination>
        </b-row>
      <b-row>
        <b-modal
            id="addgroup-form"
            title="Voeg groep toe"
            hide-footer
        >
          <b-form>
            <b-form-group
                id="input-code"
                label="Groep code"
                label-for="code">
              <b-form-input
                  id="code"
                  v-model="form.groupTag"
                  type="text"
                  placeholder="Titel voor groep"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-titel"
                label="Titel (Nederlands)"
                label-for="titel">
              <b-form-input
                  id="titel"
                  v-model="form.titel"
                  type="text"
                  placeholder="Titel voor groep"
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-omschrijving"
                label="Omschrijving"
                label-for="omschrijving">
              <b-form-textarea
                  id="omschrijving"
                  v-model="form.omschrijving"
                  type="textarea"
                  placeholder="Nederlandse omschrijving voor groep"
                  rows="5"
              ></b-form-textarea>
            </b-form-group>
            <b-button type="submit" @submit.prevent @click="addGroup" class="btn-danger w-100">Voeg toe</b-button>
          </b-form>
        </b-modal>
      </b-row>
    </b-container>
</template>

<script>
    import axios from 'axios';
    import router from "@/router";
    import config from '../../public/config/api.json';
    import {getUserInfo} from "@/utils/auth";
    let configApi = null;
    if(config.is_production) {
      configApi = config.production;
    } else {
      configApi = config.test;
    }
    export default {
        name: "GroupList",
        data() {
            return {
              userData: null,
                groups: [],
                perPage: 10,
                currentPage: 1,
                groupsLength: 0,
                fields: [
                    {
                        key: 'tag',
                        label: 'Groep-code'
                    },
                    {
                      key: 'title',
                      label: 'Naam'
                    }
                ],
              form: {
                  groupTag: '',
                  titel: '',
                  omschrijving: ''
              }
            }
        },
        methods: {
            onGroupSelect: (groupSelected) => {
                let selectedId = groupSelected.id;
                console.log('id group: ' + selectedId);
                router.push('/group/' + selectedId);
            },
          addGroup: function() {
            let formData = new FormData();

            let groupToAdd = {
                "item_type": {"id": 19},
                "collection": {"id": this.userData.collection},
                "public": false,
                "featured": false,
                "element_texts": [
                  {
                    "html": false,
                    "text": `${this.userData.collection}-${this.form.groupTag}`,
                    "element": {"id": 60}
                  },
                  {
                    "html": false,
                    "text": this.form.titel,
                    "element": {"id": 50}
                  },
                  {
                    "html": false,
                    "text": this.form.titel,
                    "element": {"id": 52}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving,
                    "element": {"id": 53}
                  }
                ]
              }
              formData.append('data', groupToAdd);
            axios.post(configApi.api_url + 'items?key=' + this.userData.apikey, groupToAdd)
            .then(() => {
              // router.push('/');
              window.location.reload();
            })
            .catch();
              console.log(JSON.stringify(groupToAdd));
          },
          rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.active === '0') return 'table-dark'
          }
        },
        created() {
          this.userData = getUserInfo();
          axios.get(configApi.api_url + 'items' + '?collection='+ this.userData.collection + '&item_type=19&key=' + this.userData.apikey)
                .then((res) => {
                    const groupsFound = res.data;
                    groupsFound.forEach((groupFound) => {
                        let groupToPush = {
                            "id": null,
                            "tag": "",
                            "title": "",
                            "active": "0"
                        };
                        groupToPush.id = groupFound.id;
                        // text fields
                        groupFound.element_texts.forEach((elementText) => {
                            if (elementText.element.name === 'Tag naam'){
                                groupToPush.tag = elementText.text;
                            }
                            if (elementText.element.name === 'Title'){
                                groupToPush.title = elementText.text;
                            }
                            if (elementText.element.name === 'Actief'){
                              groupToPush.active = elementText.text;
                            }
                        });
                        this.groups.push(groupToPush);
                    });
                    this.groupsLength = this.groups.length;
                })
                .catch(err => console.log(err));
        }
    }
</script>

<style scoped>

</style>
