<template>
    <b-container>
        <b-row>
          <h3>Groep: {{ groupTag }}: {{ groupTitle }}</h3>
        </b-row>
        <b-row>
            <b-col cols="7">
                <b-form class="w-100 mx-auto">
                    <b-form-checkbox
                    v-model="form.actief"
                    unchecked-value="0"
                    value="1"
                    @change="modNL"
                    >
                      Actief
                    </b-form-checkbox>
                    <b-tabs>
                        <b-tab title="Nederlands" active>
                            <b-form-group
                                    id="input-titel-nl"
                                    label="Titel (Nederlands)"
                                    label-for="input-titel-nl">
                                <b-form-input
                                        id="titel-nl"
                                        v-model="form.titel_nl"
                                        type="text"
                                        placeholder="Nederlandse titel voor onderwerp"
                                        @input="modNL"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-nl"
                                    label="Omschrijving (Nederlands)"
                                    label-for="input-omschrijving-nl">
                                <b-form-textarea
                                        id="omschrijving-nl"
                                        v-model="form.omschrijving_nl"
                                        type="textarea"
                                        placeholder="Nederlandse omschrijving voor onderwerp"
                                        rows="10"
                                        @input="modNL"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Duits">
                            <b-form-group
                                    id="input-titel-de"
                                    label="Titel (Duits)"
                                    label-for="input-titel-de">
                                <b-form-input
                                        id="titel-de"
                                        v-model="form.titel_de"
                                        type="text"
                                        placeholder="Duitse titel voor onderwerp"
                                        @input="modDE"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-de"
                                    label="Omschrijving (Duits)"
                                    label-for="input-omschrijving-de">
                                <b-form-textarea
                                        id="omschrijving-de"
                                        v-model="form.omschrijving_de"
                                        type="textarea"
                                        placeholder="Duitse omschrijving voor onderwerp"
                                        rows="10"
                                        @input="modDE"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Engels">
                            <b-form-group
                                    id="input-titel-en"
                                    label="Titel (Engels)"
                                    label-for="input-titel-en">
                                <b-form-input
                                        id="titel-en"
                                        v-model="form.titel_en"
                                        type="text"
                                        placeholder="Engelse titel voor onderwerp"
                                        @input="modEN"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-en"
                                    label="Omschrijving (Engelse)"
                                    label-for="input-omschrijving-en">
                                <b-form-textarea
                                        id="omschrijving-en"
                                        v-model="form.omschrijving_en"
                                        type="textarea"
                                        placeholder="Engelse omschrijving voor onderwerp"
                                        rows="10"
                                        @input="modEN"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Frans">
                            <b-form-group
                                    id="input-titel-fr"
                                    label="Titel (frans)"
                                    label-for="input-titel-fr">
                                <b-form-input
                                        id="titel-fr"
                                        v-model="form.titel_fr"
                                        type="text"
                                        placeholder="Franse titel voor onderwerp"
                                        @input="modFR"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="input-omschrijving-fr"
                                    label="Omschrijving (Frans)"
                                    label-for="input-omschrijving-fr">
                                <b-form-textarea
                                        id="omschrijving-fr"
                                        v-model="form.omschrijving_fr"
                                        type="textarea"
                                        placeholder="Franse omschrijving voor onderwerp"
                                        rows="10"
                                        @input="modFR"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-tab>
                        <b-tab title="Extra taal">
                            <b-form-group
                                id="input-titel-x"
                                label="Titel (extra taal)"
                                label-for="input-titel-x">
                                <b-form-input
                                    id="titel-x"
                                    v-model="form.titel_x"
                                    type="text"
                                    placeholder="Titel voor groep (extra taal)"
                                    @input="modX"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                id="input-omschrijving-x"
                                label="Omschrijving (extra taal)"
                                label-for="input-omschrijving-x">
                                <b-form-textarea
                                    id="omschrijving-x"
                                    v-model="form.omschrijving_x"
                                    type="textarea"
                                    placeholder="Omschrijving voor groep (extra taal)"
                                    rows="10"
                                    @input="modX"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-tab>
                      <b-tab title="Gebaren taal">
                        <b-form-group
                            id="input-titel-gt"
                            label="Titel (gebaren taal)"
                            label-for="input-titel-gt">
                          <b-form-input
                              id="titel-gt"
                              v-model="form.titel_gt"
                              type="text"
                              placeholder="Titel voor groep (gebaren taal)"
                              @input="modX"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-omschrijving-gt"
                            label="Omschrijving (gebaren taal)"
                            label-for="input-omschrijving-gt">
                          <b-form-textarea
                              id="omschrijving-x"
                              v-model="form.omschrijving_gt"
                              type="textarea"
                              placeholder="Omschrijving voor groep (gebaren taal)"
                              rows="10"
                              @input="modGT"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-tab>
                    </b-tabs>
                    <b-button type="submit" :disabled="!modifiedNL && !modifiedDE && !modifiedEN && !modifiedFR && !modifiedX && !modifiedGT" variant="primary" @submit.prevent @click="saveText">Bewaar tekstwijzigingen</b-button>
                </b-form>
            </b-col>
            <b-col>
                <b-img v-if="fileImage.url" :src="fileImage.url" center fluid class="h-auto w-75"></b-img>
                <b-form-file style="overflow: hidden"
                             class="mt-3"
                             :state="Boolean(fileImageUpload)"
                             v-model="fileImageUpload"
                             placeholder="Selecteer of sleep een afbeelding..."
                             drop-placeholder="Laat 'm hier maar vallen..."
                ></b-form-file>
                <b-button :disabled="!Boolean(fileImageUpload)" variant="primary" class="w-100 mt-3" @click="submitImageFile">Bewaar of wijzig afbeelding</b-button>
                <b-button :disabled="!fileImage.url" variant="danger" class="w-100 mt-3" @click="deleteImageFile">Verwijder afbeelding</b-button>
            </b-col>
        </b-row>
        <b-col>Lijst onderwerpen</b-col>
        <b-row>

        </b-row>
    </b-container>
</template>

<script>
    import axios from 'axios';
    import config from '../../public/config/api.json';
    import {getUserInfo} from "@/utils/auth";
    let configApi = null;
    if(config.is_production) {
        configApi = config.production;
    } else {
        configApi = config.test;
    }

    export default {
        name: "Group",
        props: ['id'],
        data() {
            return {
              userData: {},
                groupId: null,
                groupTag: '',
                groupTitle: '',
                groupOwner: null,
                form: {
                    titel_nl: '',
                    omschrijving_nl: '',
                    titel_de: '',
                    omschrijving_de: '',
                    titel_en: '',
                    omschrijving_en: '',
                    titel_fr: '',
                    omschrijving_fr: '',
                    titel_x: '',
                    omschrijving_x: '',
                    titel_gt: '',
                    omschrijving_gt: '',
                    actief: 0
                },
                fileImage: {},
                fileImageUpload: null,
                modifiedNL: false,
                modifiedDE: false,
                modifiedEN: false,
                modifiedFR: false,
                modifiedX: false,
                modifiedGT: false,
            }
        },
        methods: {
            saveText: function() {
              let formData = new FormData();

              // console.log('NL saved: ' + this.objectTitleNL);
              console.log('saveText collection: ' + this.userData.collection);
              let formDataText = {
                "item_type" : {"id" : 19},
                "collection" : {"id" : this.userData.collection},
                "element_texts" : [
                  {
                    "html": false,
                    "text": this.form.titel_nl,
                    "element": {"id" : 50}
                  },
                  {
                    "html": false,
                    "text": this.groupTag,
                    "element": {"id" : 60}
                  },
                  {
                    "html": false,
                    "text": this.form.titel_nl,
                    "element": {"id" : 52}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving_nl,
                    "element": {"id" : 53}
                  },
                  {
                    "html": false,
                    "text": this.form.titel_de,
                    "element": {"id" : 54}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving_de,
                    "element": {"id" : 55}
                  },
                  {
                    "html": false,
                    "text": this.form.titel_en,
                    "element": {"id" : 56}
                  },
                  {
                    "html": false,
                    "text": this.form.omschrijving_en,
                    "element": {"id" : 57}
                  },
                    {
                        "html": false,
                        "text": this.form.titel_fr,
                        "element": {"id" : 58}
                    },
                    {
                        "html": false,
                        "text": this.form.omschrijving_fr,
                        "element": {"id" : 59}
                    },
                    {
                      "html": false,
                      "text": this.form.titel_x,
                      "element": {"id" : 62}
                    },
                    {
                      "html": false,
                      "text": this.form.omschrijving_x,
                      "element": {"id" : 63}
                    },
                    {
                      "html": false,
                      "text": this.form.titel_gt,
                      "element": {"id" : 64}
                    },
                    {
                      "html": false,
                      "text": this.form.omschrijving_gt,
                      "element": {"id" : 65}
                    },
                  {
                    "html": false,
                    "text": this.form.actief,
                    "element": {"id" : 61}
                  }
                ]
              };
              formData.append('data', formDataText);
              axios.put(configApi.api_url + 'items/' + this.id + '?key=' + this.userData.apikey, formDataText)
                  .then(() => {
                        this.modifiedNL = false;
                        this.modifiedDE = false;
                        this.modifiedEN = false;
                        this.modifiedFR = false;
                        this.modifiedX = false;
                        this.modifiedGT = false;
                  })
                  .catch(err => {
                    let putUrl = configApi.api_url + 'items/' + this.id + '?key=' + this.userData.apikey;
                    console.log('fout: ' + err);
                    console.log('put URL: ' + putUrl);
                    console.log('put body: ' + JSON.stringify(formDataText));
                  });
            },
            modNL: function () {
              this.modifiedNL = true;
            },
            modDE: function () {
              this.modifiedDE = true;
            },
            modEN: function () {
              this.modifiedEN = true;
            },
            modFR: function () {
                this.modifiedFR = true;
            },
            modX: function () {
              this.modifiedX = true;
            },
            modGT: function () {
              this.modifiedGT = true;
            },
            submitImageFile: function () {
                // func
                let formImageData = new FormData();
                let fileImage = this.fileImageUpload;
                formImageData.append('file', fileImage);
                const recordImageData = `
              {
                  "order": 1,
                  "item": {"id": ${this.groupId}},
                  "element_texts": [
                      {
                          "html": false,
                          "text": "Afbeelding",
                          "element": {"id": 50}
                      }
                  ]
              }
              `;
                console.log('recordImageData' + recordImageData);
                formImageData.append('data', recordImageData);
                if(this.fileImage.id) {
                    // image found
                    axios.delete(configApi.api_url + 'files/' + this.fileImage.id + '?key=' + this.userData.apikey)
                        .then(() => {
                            axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                                formImageData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            ).then(function(){
                                console.log('SUCCESS!!');
                                window.location.reload();
                            })
                                .catch(function(){
                                    console.log('FAILURE!!');
                                });

                        })
                        .catch(console.log('verwijderen afbeelding: ' + this.fileImage.id + ' mislukt!'));
                } else {
                    // no image found
                    axios.post( configApi.api_url + 'files?key=' + this.userData.apikey,
                        formImageData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function(){
                        console.log('SUCCESS!!');
                        window.location.reload();
                    })
                        .catch(function(){
                            console.log('FAILURE!!');
                        });
                }
                // func
            },
            deleteImageFile: function() {
                if(this.fileImage.id) {
                    // image found
                    axios.delete(configApi.api_url + 'files/' + this.fileImage.id + '?key=' + this.userData.apikey)
                        .then(() => {
                            window.location.reload();
                        })
                        .catch(console.log('verwijderen afbeelding: ' + this.fileImage.id + ' mislukt!'));
                } else {
                    console.log('verwijderen afbeelding mislukt: geen afbeelding gevonden!');
                    window.location.reload();
                }
            }
        },
        created() {
          this.userData = getUserInfo();
            axios.get(configApi.api_url + 'items/' + this.id + '?key=' + this.userData.apikey)
            .then((res) => {
                let groupData = res.data;
                this.groupId = groupData.id;
                this.groupOwner = groupData.owner.id;
                console.log('owner group: ' + this.groupOwner);
                console.log('omeka user: ' + this.userData.apiuser);
                if(this.groupOwner !== this.userData.apiuser) {
                  alert('U mag deze gegevens niet bewerken!');
                  return window.location.href = ('/');
                }
                // console.log(JSON.stringify(res));
                // Text fields from Omeka
                groupData.element_texts.forEach((elementText) => {
                    if (elementText.element.name === 'Title'){
                        this.groupTitle = elementText.text;
                    }
                    if (elementText.element.name === 'Tag naam'){
                        this.groupTag = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_nl'){
                        this.form.titel_nl = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_nl'){
                        this.form.omschrijving_nl = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_de'){
                        this.form.titel_de = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_de'){
                        this.form.omschrijving_de = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_en'){
                        this.form.titel_en = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_en'){
                        this.form.omschrijving_en = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_fr'){
                        this.form.titel_fr = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_fr'){
                        this.form.omschrijving_fr = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_x'){
                      this.form.titel_x = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_x'){
                      this.form.omschrijving_x = elementText.text;
                    }
                    if (elementText.element.name === 'Titel_gt'){
                      this.form.titel_gt = elementText.text;
                    }
                    if (elementText.element.name === 'Omschrijving_gt'){
                      this.form.omschrijving_gt = elementText.text;
                    }
                    if (elementText.element.name === 'Actief'){
                      this.form.actief = elementText.text;
                    }
                });
            })
            .catch();
            // Files from Omeka
            axios.get(configApi.api_url + 'files/?item=' + this.id + '&key=' + this.userData.apikey)
                .then((resFiles) => {
                    let filesFound = resFiles.data;
                    // console.log(JSON.stringify(filesFound));
                    filesFound.forEach(fileFound => {
                        let fileToShow = {
                            "id": fileFound.id,
                            "label": "",
                            "url": fileFound.file_urls.original,
                            "type": fileFound.mime_type,
                            "filename": fileFound.original_filename
                        };
                        fileFound.element_texts.forEach(elementFound => {
                            if(elementFound.element.name === 'Title') {
                                fileToShow.label = elementFound.text;
                            }
                        });
                        if(fileToShow.label === 'Afbeelding' && fileToShow.type.slice(0,6) === 'image/'){
                            this.fileImage = fileToShow;
                        }
                    })
                })
                .catch();
        }
    }
</script>

<style scoped>

</style>
