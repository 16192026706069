<template>
    <b-container>
        <b-row>
            <b-table
                    id="objectlist"
                    fixed
                    striped
                    hover
                    :items="objects"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :fields="fields"
                    @row-clicked="onObjectSelect"
            >
            </b-table>
            <b-pagination
            v-model="currentPage"
            :total-rows="objectsLength"
            :per-page="perPage"
            aria-controls="objectlist"
            ></b-pagination>
        </b-row>
    </b-container>
</template>

<script>
    import axios from 'axios';
    import router from "@/router";
    import config from '../../public/config/api.json';
    import {getUserInfo} from "@/utils/auth";
    let configApi = null;
    if(config.is_production) {
      configApi = config.production;
    } else {
      configApi = config.test;
    }
    export default {
        name: "ObjectList",
        data() {
            return {
                objects: [],
                perPage: 20,
                currentPage: 1,
                objectsLength: 0,
                fields: [
                    {
                        key: 'identifier',
                        label: 'Onderwerp'
                    },
                    {
                        key: 'title',
                        label: 'Titel'
                    }
                ]
            }
        },
        methods: {
            onObjectSelect: (objectSelected) => {
                let selectedId = objectSelected.id;
                console.log('id object: ' + selectedId);
                router.push('/object/' + selectedId);
            }
        },
        created() {
          let userData = getUserInfo();
          axios.get(configApi.api_url + 'items' + '?collection='+ userData.collection + '&item_type=18&key=' + userData.apikey)
                .then((res) => {
                    const objectsFound = res.data;
                    objectsFound.forEach((objectFound) => {
                        let objectToPush = {
                            "id": null,
                            "identifier": "",
                            "title": ""
                        };
                        objectToPush.id = objectFound.id;
                        // text fields
                        objectFound.element_texts.forEach((elementText) => {
                            if (elementText.element.name === 'Identifier'){
                                objectToPush.identifier = elementText.text;
                            }
                            if (elementText.element.name === 'Title'){
                                objectToPush.title = elementText.text;
                            }
                        });
                        this.objects.push(objectToPush);
                    });
                    this.objectsLength = this.objects.length;
                })
                .catch(err => console.log(err));
        }
    }
</script>

<style scoped>

</style>
